import { template as template_3567639f31854d12a8967fea1cfc8a11 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3567639f31854d12a8967fea1cfc8a11(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
