import { template as template_8a514729e4cb4821bfb69c016d79204f } from "@ember/template-compiler";
const SidebarSectionMessage = template_8a514729e4cb4821bfb69c016d79204f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
