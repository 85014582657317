import { template as template_4fd6763c4fa74bd4bd264d2f12046fe2 } from "@ember/template-compiler";
const WelcomeHeader = template_4fd6763c4fa74bd4bd264d2f12046fe2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
