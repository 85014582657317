import { template as template_67f2667720ec4d8daba1c133b41bf457 } from "@ember/template-compiler";
const FKText = template_67f2667720ec4d8daba1c133b41bf457(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
